const userServices = `user-service/reel/api/v1/`;
const vendorServices = `vendor-service/api/v1/`;
const riskManangerServices = '';
const endPoints = {
  sendotp: `${userServices}vendor/send-otp`,
  resetpassword: `${userServices}vendor/reset-password`,
  register: `${userServices}vendor/signup`,
  login: `${userServices}vendor/login`,
  logout: `${userServices}vendor/logout`,
  verify: `${userServices}vendor/verify-otp`,
  businessRegister: `${userServices}vendor/business/registration`,
  common: {
    deparmentList: `${vendorServices}dept-list`,
    countryList: `${userServices}user/country-list`,
    categories: `${vendorServices}categories`,
  },
  staticData: {
    content: `${vendorServices}static-content/`,
  },
  chat: {
    getQuote: `${vendorServices}quotation/`,
    createChat: `${vendorServices}user/chat`,
    employeeList: `${vendorServices}user/chat/vendors`,
  },
  product: {
    create: `${vendorServices}products`,
    list: `${vendorServices}products`,
    edit: `${vendorServices}products/`,
    delete: `${vendorServices}products/`,
    update: `${vendorServices}products/`,
    statusUpdate: `${vendorServices}products/`,
    templateList: `${vendorServices}product/templates`,
    sequenceList: `${vendorServices}products/sequence`,
    package: {
      create: `${vendorServices}packages`,
      list: `${vendorServices}packages`,
      edit: `${vendorServices}packages/`,
      delete: `${vendorServices}packages/`,
      update: `${vendorServices}packages/`,
      statusUpdate: `${vendorServices}packages/`,
      templateList: `${vendorServices}packages/templates`,
      sequenceList: `${vendorServices}packages/sequence`,
    },
    productVariant: {
      createVariant: `${vendorServices}variant-products`,
      updateVariant: (id: string) => `${vendorServices}variant-products/${id}`,
      deleteProductVariant: (id: string) =>
        `${vendorServices}products/product-variant/${id}`,
      // -/products/{productId}/variant/property/{id}
      deleteAlreadyVariant: (productId: string, id: string) =>
        `${vendorServices}products/${productId}/variant/property/${id}`,
    },
  },
  order: {
    list: `${vendorServices}order`,
    cancleOrder: `${vendorServices}order/cancel`,
    productItems: `${vendorServices}order/items`,
    updateQuantity: `${vendorServices}order/quantity`,
    updateRentQuantity: (id: string) => `${vendorServices}order/${id}/qty`,
    updateStatus: `${vendorServices}order/status`,
    viewWorksheet: (id: string) =>
      `${vendorServices}order/products/${id}/worksheet`,
    extend_service: (id: string) =>
      `${vendorServices}order/products/${id}/extend-service`,
    updateShift: (id: string) => `${vendorServices}order/shifts/${id}`,
    updateStatusShift: (id: string) =>
      `${vendorServices}order/shifts/${id}/accept-reject`,
    addProducts: (id: string) => `${vendorServices}order/${id}/add-more`,
    updateAddress: `${vendorServices}order/address`,
    rentApproveReject: `${vendorServices}order/`,
    productDetails: `${vendorServices}order/products`,
    verifyRentalDetails: `${vendorServices}chat/service/validate-time`,
    createCart: `${vendorServices}cart`,
    addItemServicesToOrder: `${vendorServices}order/add-more-service`,
    updateCartOrDelete: `${vendorServices}cart`,
    quotationCart: `${vendorServices}quotation_product/cart`,
    quotationShifts: `${vendorServices}quotation_cart/shifts`,
    quotationCartDetails: `${vendorServices}quotation_cart/`,
    cartRentalDetailsUpdate: (id: string) =>
      `${vendorServices}cart/${id}/service-period`,
    getOrderStatus: (id: string) => `${vendorServices}orders/${id}/status`,
    draft: {
      CRUD: `${vendorServices}order-draft`,
      addProducts: `${vendorServices}vendor-cart/products`,
      createOrder: `${vendorServices}vendor-order`,
      createCartId: `${vendorServices}vendor-cart`,
    },
  },
  company: {
    getOrPut: `${vendorServices}companies`,
  },
  client: {
    getClientList: `${vendorServices}vendor/client`,
    getSet: `${vendorServices}vendor/client/set`,
    getClientInfo: (id: string) => `${vendorServices}vendor/client/${id}`,
    getSetDetails: `${vendorServices}vendor/client/set-location`,
    getSetOrders: `${vendorServices}vendor/client/set-order`,
    getRateAndDiscount: (id: string) =>
      `${vendorServices}vendor/client/discount/${id}`,
    postRateAndDiscount: `${vendorServices}vendor/client/discount`,
    updateRateAndDiscount: `${vendorServices}vendor/client/discount`,
    postDocument: `${vendorServices}vendor/client/document`,
    getDocuments: `${vendorServices}vendor/client/document`,
    getAssociations: (id: string) =>
      `${vendorServices}vendor/client/associations/${id}`,
    removeDiscout: (id: string) =>
      `${vendorServices}vendor/client/discount/${id}`,
  },
  branch: {
    CRUD: `${vendorServices}branches`,
    managerAddOrUpdate: `${vendorServices}branches/managers`,
    zones: `${vendorServices}branches/zones`,
    holidays: `${vendorServices}holidays`,
  },
  profile: {
    getOrUpdate: `${vendorServices}vendors/settings`,
    updatePass: `${userServices}vendor/update-password`,
  },
  //Co-vendor name changed to employ
  coVendor: {
    permissionTable: `${vendorServices}co-vendors/permissions`,
    vendorCRUD: `${vendorServices}co-vendors`,
  },
  notification: {
    updateDeviceToken: `/notification-service/reel/api/v1/user/apns/token`,
    updateDeviceTokenFCM: `/notification-service/reel/api/v1/vendor/apns/token`,
    notifications: `/notification-service/reel/api/v1/vendor/notifications`,
  },
  riskmanagement: {
    risklist: 'risk-manager-service/reel/api/v1/vendor/securityManager',
    orderrequest: 'risk-manager-service/reel/api/v1/vendor/request',
    addriskManager:
      'risk-manager-service/reel/api/v1/vendor/securityManager/add',
    setAvailability: 'risk-manager-service/reel/api/v1/vendor/securityManager/set-unavailability',
    userDetails: 'risk-manager-service/reel/api/v1/vendor/securityManager',
    updateRiskManager:
      'risk-manager-service/reel/api/v1/vendor/securityManager/edit/',
    deleteExperience:
      'risk-manager-service/reel/api/v1/vendor/securityManager/experience',
    blockUnblock:
      'risk-manager-service/reel/api/v1/vendor/securityManager/status/',
    entityListing:
      'risk-manager-service/reel/api/v1/vendor/securityManager/entity',
    countryCityListing:
      'risk-manager-service/reel/api/v1/securityManager/cities',
    countryList: 'risk-manager-service/reel/api/v1/securityManager/country',
    departmentList: 'risk-manager-service/reel/api/v1/vendor/securityManager/department',
    studioList: '/risk-manager-service/reel/api/v1/vendor/securityManager/studio',
    productionList: '/risk-manager-service/reel/api/v1/vendor/securityManager/production',
    companyList: '/risk-manager-service/reel/api/v1/vendor/securityManager/company',
    university: '/risk-manager-service/reel/api/v1/vendor/securityManager/university',
    cancleOrder: '/risk-manager-service/reel/api/v1/vendor/request/status',
     degree:'/risk-manager-service/reel/api/v1/vendor/securityManager/degree',
     addCompanyManual:'/risk-manager-service/reel/api/v1/vendor/securityManager/company',
     addSchoolManual:'/risk-manager-service/reel/api/v1/vendor/securityManager/university',
  },
  group: {
    addGroup: `${vendorServices}product/group`,
    getGroups: `${vendorServices}product/groups`,
    editGroup: (id: string) => `${vendorServices}product/groups/${id}`,
    getGroupDetails: (id: string) => `${vendorServices}product/groups/${id}`,
    deleteItem: (query: string) =>
      `${vendorServices}product/groups/item${query}`,
  },
};

export default endPoints;
