import { createSlice } from '@reduxjs/toolkit';
import { notify } from '../Utils/toastify';
import endPoints from '../api/endpoint';
import {
  apiSuccessCode,
  deleteApiCall,
  getApiCall,
  postApiCall,
  putApiCall,
} from '../api/methods';
import { ApiStatus, GroupState } from '../types';
import { logout } from './auth.slice';
import store, { RootState } from './store';
import dayjs from 'dayjs';
import {
  BACKEND_P_TYPES,
  PRODUCT_TABS,
  PRODUCT_TYPE_BOTH,
  PRODUCT_TYPES,
  STATUS,
} from '../Utils/constantData';
import { updateActionApiStatus } from './common.slice';

const initialState: GroupState = {
  products: {},
  groups: {},
  groupDetails: {},
  departmentAndCategory: {
    department: '',
    category: '',
  },
  selectedIds: [],
  tableProduts: [],
  selectedAllProducts: [
    // {
    //   _id: '66cea2db140c390736fdc9ac',
    //   deliveryFee: 0,
    //   images: [
    //     'https://app-development.s3.amazonaws.com/ReelProject/1704976649197_1700735310752_mern.png',
    //   ],
    //   status: 'ACTIVE',
    //   id: 'PR1724818139795C35',
    //   type: 'RENTAL',
    //   sku: 'asdfasddfge',
    //   rentPricing: [
    //     {
    //       _id: '66cea2db140c39950dfdc9ad',
    //       minDuration: 34,
    //       minRateModel: 'DAILY',
    //       rentPrice: 34,
    //       extendedPriceMode: 'BASE_RATE',
    //       baseRate: 34,
    //       priceMode: 'PER_HOUR',
    //     },
    //   ],
    //   title: 'with',
    //   qty: 45,
    //   thumbnailImg:
    //     'https://app-development.s3.amazonaws.com/ReelProject/1704977767046_croppedImage.jpg',
    //   vendorId: '65dedbcc8d8e1931e80531a6',
    //   outForRent: 0,
    //   totalQtyEver: 45,
    //   restockedOn: '2024-08-28T04:08:59.796Z',
    //   createdAt: '2024-08-28T04:08:59.798Z',
    //   categoryData: [
    //     {
    //       _id: '667d5f0c251d245138b9c086',
    //       categoryName: 'name names',
    //     },
    //   ],
    //   variantData: [],
    //   sequenceExists: 0,
    //   perHourModes: [
    //     {
    //       _id: '66cea2db140c39950dfdc9ad',
    //       minDuration: 34,
    //       minRateModel: 'DAILY',
    //       rentPrice: 34,
    //       extendedPriceMode: 'BASE_RATE',
    //       baseRate: 34,
    //       priceMode: 'PER_HOUR',
    //     },
    //   ],
    //   perDayWeekMonthModels: [],
    //   variations: [],
    // },
    // {
    //   _id: '66cea298140c39a53cfdc98d',
    //   deliveryFee: 0,
    //   images: [
    //     'https://app-development.s3.amazonaws.com/ReelProject/1704976649197_1700735310752_mern.png',
    //   ],
    //   status: 'ACTIVE',
    //   id: 'PR1724818072574F1C',
    //   type: 'RENTAL',
    //   sku: 'asdfasddf',
    //   rentPricing: [
    //     {
    //       _id: '66cea298140c39cc3dfdc98e',
    //       minDuration: 2,
    //       minRateModel: 'DAILY',
    //       rentPrice: 23,
    //       extendedPriceMode: 'BASE_RATE',
    //       baseRate: 23,
    //       priceMode: 'PER_HOUR',
    //     },
    //   ],
    //   title: 'withoutt',
    //   qty: 45,
    //   thumbnailImg:
    //     'https://app-development.s3.amazonaws.com/ReelProject/1704977767046_croppedImage.jpg',
    //   vendorId: '65dedbcc8d8e1931e80531a6',
    //   outForRent: 0,
    //   totalQtyEver: 45,
    //   restockedOn: '2024-08-28T04:07:52.574Z',
    //   createdAt: '2024-08-28T04:07:52.726Z',
    //   categoryData: [
    //     {
    //       _id: '667d5f0c251d245138b9c086',
    //       categoryName: 'name names',
    //     },
    //   ],
    //   variantData: [],
    //   sequenceExists: 0,
    //   perHourModes: [
    //     {
    //       _id: '66cea298140c39cc3dfdc98e',
    //       minDuration: 2,
    //       minRateModel: 'DAILY',
    //       rentPrice: 23,
    //       extendedPriceMode: 'BASE_RATE',
    //       baseRate: 23,
    //       priceMode: 'PER_HOUR',
    //     },
    //   ],
    //   perDayWeekMonthModels: [],
    //   variations: [],
    // },
    // {
    //   _id: '66cdb3cbfa9a073b0fa6f98c',
    //   deliveryFee: 0,
    //   images: [
    //     'https://app-development.s3.amazonaws.com/ReelProject/1704976649197_1700735310752_mern.png',
    //   ],
    //   status: 'ACTIVE',
    //   id: 'PR1724756939881B4E',
    //   type: 'RENTAL',
    //   sku: 'wkhsdf',
    //   rentPricing: [
    //     {
    //       _id: '66cdb3cbfa9a071fd5a6f98d',
    //       minDuration: 2,
    //       minRateModel: 'DAILY',
    //       rentPrice: 234,
    //       extendedPriceMode: 'BASE_RATE',
    //       baseRate: 32,
    //       priceMode: 'PER_HOUR',
    //     },
    //   ],
    //   title: 'Latest WithOut',
    //   qty: 33,
    //   thumbnailImg:
    //     'https://app-development.s3.amazonaws.com/ReelProject/1704977767046_croppedImage.jpg',
    //   vendorId: '65dedbcc8d8e1931e80531a6',
    //   outForRent: 0,
    //   totalQtyEver: 33,
    //   restockedOn: '2024-08-27T11:08:59.881Z',
    //   createdAt: '2024-08-27T11:08:59.886Z',
    //   categoryData: [
    //     {
    //       _id: '667d5f0c251d245138b9c086',
    //       categoryName: 'name names',
    //     },
    //   ],
    //   variantData: [],
    //   sequenceExists: 0,
    //   perHourModes: [
    //     {
    //       _id: '66cdb3cbfa9a071fd5a6f98d',
    //       minDuration: 2,
    //       minRateModel: 'DAILY',
    //       rentPrice: 234,
    //       extendedPriceMode: 'BASE_RATE',
    //       baseRate: 32,
    //       priceMode: 'PER_HOUR',
    //     },
    //   ],
    //   perDayWeekMonthModels: [],
    //   variations: [],
    // },
  ],
  page: 1,
  searchKeywords: '',
  type: '',
  loading: false,
  filter: null,
  filterStatus: false,
};

const handleError = (e: any) => {
  const { dispatch } = store;
  setTimeout(() => {
    dispatch(updateActionApiStatus(ApiStatus.failed));
  }, 1500);

  if (e.data && e.data.message) {
    notify(e.data.message, 'error');
  }
  if (e.data && e.data.statusCode === 401) {
    logout();
  }
};

const GroupManagement: any = createSlice({
  name: 'Group management',
  initialState,
  reducers: {
    updateGroupManagement: (state: any, action: any) => {
      return { ...state, ...action.payload };
    },
    resetGroupManagement: (state, action: any) => {
      return initialState;
    },
  },
});

export const { updateGroupManagement, resetGroupManagement } =
  GroupManagement.actions;

export default GroupManagement.reducer;

// ------------- ************** -------------

// API CALLS METHODS

export const setDeptAndCat = (value: any) => {
  const { getState, dispatch } = store;

  dispatch(updateGroupManagement({ departmentAndCategory: value }));
};

export const addProductsHandle = () => {
  const { getState, dispatch } = store;

  const {
    Group: { selectedAllProducts },
  }: RootState = getState();

  dispatch(updateGroupManagement({ tableProduts: selectedAllProducts }));
};

export const selectedProducts = (payload: any) => {
  const { getState, dispatch } = store;
  dispatch(updateGroupManagement({ selectedIds: payload }));
};

export const setSelectedAllProducts = (payload: any) => {
  const { getState, dispatch } = store;
  // console.log('payloooooo=>>>', payload);
  dispatch(updateGroupManagement({ selectedAllProducts: payload }));
};

export const setSearchKeywords = (payload: any) => {
  const { getState, dispatch } = store;
  dispatch(updateGroupManagement({ searchKeywords: payload }));
};

export const setFilterStatus = (payload: any) => {
  const { getState, dispatch } = store;
  dispatch(updateGroupManagement({ filterStatus: payload }));
};

// Get products method

export const getAndSetGroupsList = (
  type: string = '',
  query: string = '?pageNo=1&limit=10&sortBy=sequenceNo&sortOrder=1',
  branchId: string | null = null,
  notQuery: string = '',
  isActiveOnlyFilter: boolean = false,
): any => {
  let apiEndpoint = endPoints.product.list;

  const { getState, dispatch } = store;
  const {
    Group: { searchKeywords },
  }: RootState = getState();
  // console.log('searchKeywords', searchKeywords);
  const searchQuery = searchKeywords
    ? `&searchKey=${encodeURIComponent(searchKeywords)}`
    : '';
  const filters = store.getState().product.filter;
  let filterQuery = '';
  if (filters) {
    if (filters.fromDate) {
      filterQuery += `&fromDate=${filters.fromDate}`;
    }
    if (filters.toDate) {
      const toDate = dayjs(filters.toDate).toDate();
      toDate.setDate(toDate.getDate() + 2);
      filterQuery += `&toDate=${toDate.toISOString()}`;
    }
    if (filters.department && type !== PRODUCT_TABS.package.value) {
      filterQuery += `&department=${filters.department._id}`;
    } else if (filters.department && type === PRODUCT_TABS.package.value) {
      filterQuery += `&departments=${filters.department._id}`;
    }
    if (filters.type) {
      const pType =
        filters.type === PRODUCT_TYPES.rentAndPurchase
          ? BACKEND_P_TYPES.RENTAL_AND_PURCHASE
          : filters.type === PRODUCT_TYPE_BOTH.allProducts
          ? PRODUCT_TYPE_BOTH.allProducts
          : filters.type.toUpperCase();
      filterQuery += `&type=${pType}`;
    }
    if (filters.isFeaturedProducts) {
      filterQuery += `&isFeaturedProducts=${filters.isFeaturedProducts}`;
    }
  }
  if (branchId) {
    filterQuery += `&branchId=${branchId}`;
  }
  let listType = '';
  if (Boolean(type)) {
    listType += `&listType=${type}`;
  }
  if (type === PRODUCT_TABS.package.value) {
    apiEndpoint = endPoints.product.package.list;
    listType = ''; ///Reseting listType to blank in case of packgage
  }
  if (Boolean(notQuery)) {
    listType += `&not=${
      notQuery === PRODUCT_TYPES.rentAndPurchase
        ? BACKEND_P_TYPES.RENTAL_AND_PURCHASE
        : notQuery.toUpperCase()
    }`;
  }
  if (isActiveOnlyFilter) {
    listType += `&isActiveOnly=${isActiveOnlyFilter}`;
  }

  const params = `${query}${listType}${searchQuery}${filterQuery}`;

  // dispatch(setProductLoader(true));

  getApiCall(
    `${apiEndpoint}${params}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        //   dispatch(setProductList(data.data));
        // console.log('DATA =>>>>>', data.data);
        dispatch(updateGroupManagement({ products: data?.data || {} }));
      }
      // dispatch(setProductLoader(false));
    },
    (e: any) => {
      // dispatch(setProductList([]));
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      } else {
        notify(`Error while fetching ${type} list`, 'error');
      }
      if (e.data && e.data.statusCode === 401) {
        logout();
      }
      // dispatch(setProductLoader(false));
    },
    branchId ? { ['branch_id']: branchId } : {},
  );
};

// Add Group method

export const addGroup = (values: any, callback?: any) => {
  // console.log('Body => ', values);
  // console.log('dataToSend', values)
  const { getState, dispatch } = store;
  dispatch(updateGroupManagement({ loading: true }));

  postApiCall(
    endPoints.group.addGroup,
    values,
    (s: any) => {
      const {
        data: { statusCode },
      } = s;

      // console.log('Product banner', s)
      if (statusCode && statusCode === 201) {
        dispatch(updateGroupManagement({ loading: false }));
        callback && callback();
      }
    },
    (e: any) => {
      //   setLoad(false)
      if (e?.data && e?.data.message) {
        dispatch(updateGroupManagement({ loading: false }));

        notify(e?.data.message, 'error');
      } else {
        notify(null, 'error');
      }
    },
  );
};

// Edit Group

export const editGroup = (id: string, values: any, callback?: any) => {
  // console.log('Body => ', values);
  // console.log('dataToSend', values)
  const { getState, dispatch } = store;

  dispatch(updateGroupManagement({ loading: true }));

  putApiCall(
    endPoints.group.editGroup(id),
    values,
    (s: any) => {
      const {
        data: { statusCode },
      } = s;

      // console.log('Product banner', s)
      if (statusCode && statusCode === 202) {
        dispatch(updateGroupManagement({ loading: false }));

        callback && callback();
      }
    },
    (e: any) => {
      //   setLoad(false)
      if (e?.data && e?.data.message) {
        dispatch(updateGroupManagement({ loading: false }));

        notify(e?.data.message, 'error');
      } else {
        notify(null, 'error');
      }
    },
  );
};

export const setGroupSearchKeywords = (value: any) => {
  const { getState, dispatch } = store;
  dispatch(updateGroupManagement({ searchKeywords: value, page: 1 }));
};

export const setGroupFilters = (value: any) => {
  const { getState, dispatch } = store;
  dispatch(updateGroupManagement({ filter: value }));
};

export const getGroups = (params?: any) => {
  const { getState, dispatch } = store;

  dispatch(updateGroupManagement({ loading: true }));
  const { searchKeywords, filter } = getState().Group;
  const { page } = getState().common;

  const { department, category, isFeaturedProducts } = filter || {};

  let query = '&sortBy=sequenceNo&sortOrder=1';

  if (searchKeywords) {
    query += `&searchKey=${searchKeywords}`;
  }
  if (department) {
    query += `&departmentId=${department?._id}`;
  }
  if (category) {
    query += `&categoryId=${category?._id}`;
  }

  getApiCall(
    `${endPoints.group.getGroups}?pageNo=${page}&limit=10${query}`,
    (e: any) => {
      const { data } = e;
      if (data && data.data) {
        // console.log('DDDD =>', data);
        dispatch(updateGroupManagement({ groups: data?.data }));
      }
      dispatch(updateGroupManagement({ loading: false }));
    },
    (e: any) => {
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      } else {
        notify(`Error while fetching groups`, 'error');
      }
      if (e.data && e.data.statusCode === 401) {
        logout();
      }
      dispatch(updateGroupManagement({ loading: false }));
    },
  );
};

export const getGroupDetails = (id?: any, cb?: any) => {
  const { getState, dispatch } = store;
  dispatch(updateGroupManagement({ loading: true }));
  getApiCall(
    `${endPoints.group.getGroupDetails(id)}`,
    (e: any) => {
      const { data } = e;
      if (data && data.data) {
        // console.log('DDDD =>', data);
        dispatch(updateGroupManagement({ groupDetails: data?.data }));
        cb && cb(data?.data);
      }
      dispatch(updateGroupManagement({ loading: false }));
    },
    (e: any) => {
      if (e.data && e.data.message) {
        notify(e.data.message, 'error');
      } else {
        notify(`Error while fetching groups`, 'error');
      }
      if (e.data && e.data.statusCode === 401) {
        logout();
      }
      dispatch(updateGroupManagement({ loading: false }));
    },
  );
};

export const toggleStatusGroup = (
  e: React.ChangeEvent<HTMLInputElement>,
  id: string,
) => {
  const status = e.target.checked ? STATUS.ACTIVE : STATUS.INACTIVE;
  putApiCall(
    endPoints.group.editGroup(id),
    { status },
    (s: any) => {
      const {
        data: { statusCode },
      } = s;

      // console.log('Product banner', s)
      if (statusCode && statusCode === 201) {
        e.target.checked
          ? notify('Group is active', 'success')
          : notify('Group is inactive', 'success');
        // dispatch(updateGroupManagement({ loading: false }));
        // callback && callback();
      }
    },
    (e: any) => {
      //   setLoad(false)
      if (e?.data && e?.data.message) {
        // dispatch(updateGroupManagement({ loading: false }));

        notify(e?.data.message, 'error');
      } else {
        notify(null, 'error');
      }
    },
  );
};

export const deleteItem = (query: string, cb?: any, errorCB?: any) => {
  const { dispatch } = store;

  deleteApiCall(
    `${endPoints.group.deleteItem(query)}`,
    {},
    (d: any) => {
      notify('item remove successfully', 'success');
      cb();
      // actionLoader(ApiStatus.succeeded);
    },
    (e: any) => {
      handleError(e);
      errorCB();
    },
  );
};

// const unnormalizedData = {
//   users: [
//     {
//       id: 1,
//       name: 'Alice',
//       posts: [
//         {
//           id: 1001,
//           title: "Alice's first post",
//           content: 'This is a post by Alice.',
//           comments: [
//             {
//               id: 5001,
//               content: 'Great post!',
//               author: { id: 2, name: 'Bob' },
//             },
//           ],
//           tags: ['JavaScript', 'Web Development'],
//         },
//       ],
//     },
//     {
//       id: 2,
//       name: 'Bob',
//       posts: [
//         {
//           id: 1002,
//           title: "Bob's thoughts",
//           content: 'This is a post by Bob.',
//           comments: [
//             {
//               id: 5002,
//               content: 'Interesting!',
//               author: { id: 1, name: 'Alice' },
//             },
//           ],
//           tags: ['Python', 'AI'],
//         },
//       ],
//     },
//   ],
// };

// function normalizeAdvancedData(data) {
//   const usersMap = new Map();
//   const posts = [];
//   const comments = [];
//   const tagsMap = new Map();
//   let tagIdCounter = 1;

//   // Normalize users
//   data.users.forEach((user) => {
//     usersMap.set(user.id, { id: user.id, name: user.name });

//     // Normalize posts
//     user.posts.forEach((post) => {
//       const comment_ids = post.comments.map((comment) => {
//         // Normalize comment authors
//         if (!usersMap.has(comment.author.id)) {
//           usersMap.set(comment.author.id, {
//             id: comment.author.id,
//             name: comment.author.name,
//           });
//         }

//         comments.push({
//           id: comment.id,
//           content: comment.content,
//           author_id: comment.author.id,
//           post_id: post.id,
//         });

//         return comment.id;
//       });

//       const tag_ids = post.tags.map((tag) => {
//         if (!tagsMap.has(tag)) {
//           tagsMap.set(tag, { id: tagIdCounter, name: tag });
//           tagIdCounter++;
//         }
//         return tagsMap.get(tag).id;
//       });

//       posts.push({
//         id: post.id,
//         title: post.title,
//         content: post.content,
//         author_id: user.id,
//         comment_ids,
//         tag_ids,
//       });
//     });
//   });

//   console.log('usersMap', usersMap);

//   const normalizedData = {
//     users: Array.from(usersMap.values()),
//     posts,
//     comments,
//     tags: Array.from(tagsMap.values()),
//   };

//   return normalizedData;
// }

// const normalizedData = normalizeAdvancedData(unnormalizedData);
// console.log('normalizedData', normalizedData);
