import React from 'react';
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    disableTouchListener={false}
    enterTouchDelay={0}
    placement="top"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000000',
    opacity: '1 !important',
    color: '#ffffff !important',
    // boxShadow: theme.shadows[1],
    fontSize: '14px',
    fontFamily: 'Poppins',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    maxWidth: 331,
    fontWeight: 300,
    boxShadow: '-1px 1px 8px 1px rgb(0 0 0 / 17%)',
    padding: '8px 12px',
    borderRadius: '4px',
    '& span': {
      // top: '4px !important',
      '&:before': {
        backgroundColor: '#000000',
        // opacity: '0.7 !important',
        // backgroundImage: `url(${Images.toop_tip_arrow_white})`
      },
    },
  },
}));

const DarkLgTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    disableTouchListener={false}
    enterTouchDelay={0}
    placement="top"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000000',
    opacity: '1 !important',
    color: '#ffffff !important',
    // boxShadow: theme.shadows[1],
    fontSize: '14px',
    fontFamily: 'Poppins',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    maxWidth: 410,
    fontWeight: 300,
    boxShadow: '-1px 1px 8px 1px rgb(0 0 0 / 17%)',
    padding: '8px 12px',
    borderRadius: '4px',
    '& span': {
      // top: '4px !important',
      '&:before': {
        backgroundColor: '#000000',
        // opacity: '0.7 !important',
        // backgroundImage: `url(${Images.toop_tip_arrow_white})`
      },
    },
  },
}));

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    disableTouchListener={false}
    enterTouchDelay={0}
    placement="top"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    color: '#233E76',
    // boxShadow: theme.shadows[1],
    fontSize: '14px',
    fontFamily: 'Roboto',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    maxWidth: 331,
    fontWeight: 300,
    boxShadow: '-1px 1px 8px 1px rgb(0 0 0 / 17%)',
    padding: '8px 12px',
    borderRadius: '4px',
    '& span': {
      // top: '4px !important',
      '&:before': {
        backgroundColor: '#FFFFFF',
        // backgroundImage: `url(${Images.toop_tip_arrow_white})`
      },
    },
  },
}));

const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    disableTouchListener={false}
    enterTouchDelay={0}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#dc362e',
    color: '#ffffff',
    // boxShadow: theme.shadows[1],
    fontSize: '14px',
    fontFamily: 'Roboto',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    maxWidth: 331,
    fontWeight: 400,
    boxShadow: '-1px 1px 8px 1px rgb(0 0 0 / 17%)',
    padding: '8px 12px',
    borderRadius: '4px',
    '& span': {
      // top: '4px !important',
      '&:before': {
        backgroundColor: '#dc362e',
        // backgroundImage: `url(${Images.toop_tip_arrow_white})`
      },
    },
  },
}));
interface Props {
  title?: any;
  children: React.ReactElement;
  arrow?: boolean;
  theme?: 'dark' | 'light' | 'error' | 'darkLg';
  isLink?: boolean;
  position?: 'top' | 'bottom' | 'left' | 'right';
}

const ToolTipComponent: React.FC<Props> = (props) => {
  const { title, children, arrow, theme, position } = props;
  return (
    <>
      {theme === 'light' ? (
        <LightTooltip
          title={title}
          arrow={arrow ? true : false}
          placement="bottom"
        >
          {children}
        </LightTooltip>
      ) : theme === 'error' ? (
        <ErrorTooltip
          title={title}
          arrow={arrow ? true : false}
          placement="bottom"
        >
          {children}
        </ErrorTooltip>
      ) : theme === 'darkLg' ?
      (
        <DarkLgTooltip
          title={title}
          arrow={arrow ? true : false}
          placement="bottom"
        >
          {children}
        </DarkLgTooltip>
      ) :
      (
        <DarkTooltip
          title={title}
          arrow={arrow ? true : false}
          placement="bottom"
        >
          {children}
        </DarkTooltip>
      )}
    </>

    // <Tooltip title={title} arrow>
    //   {children}
    // </Tooltip>
  );
};

export default ToolTipComponent;
