import { tranlate } from '../LocalizationJson/i18';

const t = (k: string, params: any = {}) =>
  tranlate(`validation_msgs.${k}`, params);

export const ROUTES = {
  LOGIN: '/login',
  VERIFY: '/verify',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/resetPassword',
  SIGNUP: '/signup',
  DASHBOARD: '/', /// Default route set to product page as currently don't have dashboard design
  WELCOME: '/welcome',
  BUSINESSINFO: '/business-info',
  PRODUCTS: '/products',
  ADD_PRODUCTS: '/add-product',
  EDIT_PRODUCTS: '/edit-product/:id',
  ADD_VARIANT: '/edit-product/add-variant/:id',
  EDIT_VARIANT: '/edit-product/edit-variant/:parentId/:id',
  ADD_SERVICES: '/add-services',
  EDIT_SERVICE: '/edit-service/:id',
  ADD_PACKAGE: '/add-package',
  EDIT_PACKAGE: '/edit-package/:id',
  ADD_SERVICE_VARIANT: '/edit-service/add-variant/:id',
  EDIT_SERVICE_VARIANT: '/edit-service/edit-variant/:parentId/:id',
  ORDERS: '/orders',
  ORDERS_DETAILS: '/order-details/:id',
  DRAFT_DETAILS: '/draft-details/:id',
  ORDERS_WORKSHEET: '/order-worksheet',
  ORDERS_PRODUCTS: '/order-products',
  ADD_PRODUCTS_ORDERS: '/add-products-order',
  BRANCHES: '/branches',
  ADD_BRANCH: '/add-branch',
  EDIT_BRANCH: '/edit-branch/:id',
  VIEW_BRANCH: '/view-branch/:id',
  FINANCIALS: '/finance',
  FINANCIAL_DETAIL: '/finance/detail',
  CHATS: '/chats',
  ORDER_SERVICES: '/chats/orders',
  CREATE_ORDER: '/chats/create-order',
  EDIT_ORDER: '/chats/edit-order/:id',
  COMPANY: '/company',
  CLIENTS: '/clients',
  CLIENTSETDETAILS: '/clients/set-detail/:id',
  CLIENTDETAILS: '/clients/client-detail/:id',
  UNDERDEVELOPMENT: '/underdevelopment',
  PROFILE: '/profile',
  POLICIES: '/policies',
  TERMS: '/terms-conditions',
  FAQ: '/faq',
  SUPPORT: '/support',
  EMPLOY: '/employ',
  ADD_EMPLOY: '/add-employ',
  EDIT_EMPLOY: '/edit-employ/:id',
  VIEW_EMPLOY: '/view-employ/:id',
  DRIVERS: '/drivers',
  DRIVER_ADD: '/driver/add',
  DRIVER_VIEW: '/driver/detail',
  VEHICLE_ADD: '/vehicle/add',
  VEHICLE_VIEW: '/vehicle/detail',
  RISK_MANAGEMENT: '/risk-management',
  ADD_RISK_USER: '/add-user',
  VIEW_RISK_USER: '/view-user/:id',
  EDIT_RISK_USER: '/edit-user/:id',
  GROUPS: '/groups',
  ADD_GROUP: '/add-group',
  EDIT_GROUP: '/edit-group/:id',
};

export const ErrorMsg = (
  value: number | string = '',
  limit: string | number = '',
) => {
  return {
    min: t(`min`, { amount: value }),
    max: t(`max`, { amount: value }),
    required: t(`required`, { name: value }),
    onlyLetter: t(`onlyLetter`, { amount: value }),
    valid: t(`valid`, { name: value }),
    typeError: t('typeErrorNumber'),
    positive: t('positive'),
    integer: t('integer'),
    percentage: t('percentage'),
    invalid: t('invalid'),
    fileSizeError: t(`fileSizeError`, { amount: value }),
    minCharLimit: t(`minCharLimit`, { name: value, limit }),
    maxCharLimit: t(`maxCharLimit`, { name: value, limit }),
    passwordError: t('passwordError'),
    terms: t('terms'),
    twoDecimalPoints: t('twoDecimalPoints'),
    specialCharNotAlld: t('specialCharNotAlld'),
    futureDate: t('futureDate'),
  };
};
export const allowedImageMimeType = [
  'image/jpeg', //.jpg | .jpeg
  'image/png', // .png
  'image/gif', // .gif
  'image/svg+xml', // .svg,
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.svg',
  '.heic',
];
export const allowedAudioMimeType = [
  'audio/aac', // .aac
  'audio/mp4', // .m4a
  'audio/x-wav', // .wav .wave
  'audio/x-aiff', //.aff .aif .aiff
  'application/ogg', // .ogg
  'audio/mpeg', // .ogg,
  '.ogg',
  '.aff',
  '.aif',
  '.aiff',
  '.wav',
  '.wave',
  '.m4a',
  '.aac',
];
export const allowedVideoMimeType = [
  'video/x-ms-wmv', // .wmv
  'video/avi', // .avi
  'video/quicktime', // .mov
  'video/mp4', // .mp4
  'video/x-flv', // .flv
  '.flv',
  '.mp4',
  '.mov',
  '.avi',
  '.wmv',
  '.heic',
];
export const allowedDocsMimeType = [
  'application/pdf', //.pdf
  'application/vnd.ms-excel', // MS Excel File (.xlsx,. xls)
  '.pdf',
  '.xls',
];
export const allMediaMimeType = [
  ...allowedDocsMimeType,
  ...allowedAudioMimeType,
  ...allowedImageMimeType,
  ...allowedVideoMimeType,
];
export const CHAT_ACTIVE = 'ACTIVE';
export const CHAT_DELETED = 'DELETED';
export const CHAT_UPLOADING = 'UPLOADING';
export const CHAT_FAILED = 'FAILED';
export const CHAT_ORDER_COMPLETED = 'ORDER_COMPLETED';
export const MEDIA_TYPES = ['IMAGE', 'MEDIA', 'DOCS', 'VIDEO'];
export const USER_VENDOR_CHAT = 'user_vendor_chat';
export const VENDOR_VENDOR_CHAT = 'vendor_vendor_chat';
export const VENDOR_ADMIN_CHAT = 'vendor_admin_chat';
export const MaxFileSizeLimit = 10485760;
export const DEFAULT_URL = [
  'https://app-development.s3.amazonaws.com/ReelProject/1701757389017user%20%281%29.png',
  'https://app-development.s3.amazonaws.com/ReelProject/1709702563475_User_Placeholder.png',
];
export const DEFAULT_ADMIN_ID = '66705d5d0b99f01dd013ab7c';
export const ADMIN_FIRSTNAME = 'Reeltrack Support';
export const ADMIN_PROFILE_PIC =
  'https://pre-images.reeltrak.com/ReelProject/imgpsh_fullsize_anim.png';
export const USER_TYPE_MAP = {
  [VENDOR_ADMIN_CHAT]: 'ADMIN',
  [VENDOR_VENDOR_CHAT]: 'VENDOR',
  [USER_VENDOR_CHAT]: 'USER',
};
const GOOGLE_MAPS_API_KEY = 'AIzaSyCu3pQ2rxalRAWIONNcxoYST8Cy7NJrveA';
export const GOOGLE_MAP_API = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
