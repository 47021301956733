import store, { RootState, persistor } from './store';
import { createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { encryptData, generateRandomString } from '../Utils/helperFns';
import { notify } from '../Utils/toastify';
import { resetAuthorizationToken, setAuthorizationToken } from '../api';
import endPoints from '../api/endpoint';
import {
  apiSuccessCode,
  getApiCall,
  postApiCall,
  putApiCall,
} from '../api/methods';
import { ApiStatus, AuthState } from '../types';
import { PERMISSION_TYPES, USER_ROLES } from '../Utils/constantData';
import { LoginFormInterface } from '../Pages/Login/Login';
import { setCommonDataLoader } from './common.slice';
import { resetList } from './notification.slice';

const initialState: AuthState = {
  status: false,
  userData: null,
  token: null,
  disclamerPopUpVisibile: false,
  apiStatus: ApiStatus.idle,
  permissionToAdd: true,
  rejectBanner: '',
  rejectReason: {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      const {
        payload: { userData, token },
      } = action;
      state.status = true;
      state.userData = userData;
      state.token = token;

      setAuthorizationToken(token);
      if (['REJECTED', 'PENDING']?.includes(userData?.status)) {
        state.rejectBanner = userData?.status;
        state.rejectReason = userData?.rejectionInfo;
      }
    },
    logoutState: (state) => {
      state.status = false;
      state.userData = null;
      state.rejectBanner = '';
      state.rejectReason = {};
      state.token = null;
      const encrpted = localStorage.getItem('e');
      const userId = localStorage.getItem('p');
      const fcmToken = localStorage.getItem('tokenFCM');
      localStorage.clear();
      fcmToken && localStorage.setItem('tokenFCM', fcmToken);
      encrpted && localStorage.setItem('e', encrpted);
      userId && localStorage.setItem('p', userId);
      resetAuthorizationToken();
      // persistor.purge();
    },
    updateStep: (state, action) => {
      if (state.userData == null) {
        state.userData = { formNextStep: action.payload.step };
      } else {
        state.userData['formNextStep'] = action.payload.step;
      }
    },
    saveUserData: (state, action) => {
      const {
        payload: { key, value },
      } = action;
      if (key === undefined) {
        state.userData = value;
      } else if (state.userData == null) {
        state.userData = { [key]: value };
      } else {
        state.userData[key] = value;
      }
    },
    updateUserData: (state, action) => {
      const {
        payload: { key, value },
      } = action;
      if (key === undefined) {
        state.userData = value;
      } else if (state.userData == null) {
        state.userData = { [key]: value };
      } else {
        state.userData[key] = value;
      }
    },
    setDisclamerPopUpVisibility: (state, action) => {
      const { payload } = action;
      state.disclamerPopUpVisibile = payload;
      return state;
    },
    updateApiStatus: (state, action) => {
      const { payload } = action;
      state.apiStatus = payload;
    },
    updateBannerStatus: (state, action) => {
      const { payload } = action;
      state.rejectBanner = payload;
    },
    updateBannerReason: (state, action) => {
      const { payload } = action;
      state.rejectReason = payload;
    },

    updatePermissionToAdd: (state, action) => {
      const { payload } = action;
      const {
        userData: { role, permissions },
      } = state;
      if (
        role === USER_ROLES.CO_VENDOR &&
        permissions &&
        permissions[payload]
      ) {
        state.permissionToAdd = permissions[payload] === PERMISSION_TYPES.EDIT;
      } else {
        state.permissionToAdd = true;
      }
    },
  },
});

export const {
  login,
  logoutState,
  updateStep,
  saveUserData,
  setDisclamerPopUpVisibility,
  updateApiStatus,
  updatePermissionToAdd,
  updateBannerStatus,
  updateBannerReason,
} = authSlice.actions;

export default authSlice.reducer;

const handleError = (e: any) => {
  const { dispatch } = store;
  dispatch(updateApiStatus(ApiStatus.failed));
  if (e?.data && e?.data.message) {
    notify(e?.data.message, 'error');
  } else {
    notify(null, 'error');
  }
};

const getNotificationToken = () => {
  let token = '';
  if ('safari' in window) {
    const permissionData = window.safari.pushNotification.permission(
      `${process.env.WEB_PUSH_ID}`,
    );
    if (permissionData.permission === 'granted') {
      token = permissionData.deviceToken;
    } else {
      token = generateRandomString(5);
    }
  } else {
    token = `${localStorage.getItem('tokenFCM') || generateRandomString(5)}`;
  }
  return token;
};

export const loginCall = (data: LoginFormInterface) => {
  toast.dismiss();
  const { rememberMe, email, password } = data;
  const { dispatch } = store;
  dispatch(updateApiStatus(ApiStatus.loading));
  const payload = {
    email,
    password,
    deviceId: `${generateRandomString(5)}_${
      localStorage.getItem('email') ? localStorage.getItem('email') : ' '
    }`,
    deviceToken: getNotificationToken(),
    browser: navigator.userAgent,
    isSafariBrowser: 'safari' in window,
  };

  postApiCall(
    endPoints.login,
    payload,
    (s: AxiosResponse) => {
      const {
        data: { data },
      } = s;
      if (data) {
        dispatch(
          login({
            status: true,
            userData: { ...data, email },
            token: data.accessToken,
          }),
        );
        if (rememberMe) {
          localStorage.setItem(
            'e',
            encryptData({ email, password }, data.userId),
          );
          localStorage.setItem('p', data.userId);
        } else {
          localStorage.removeItem('e');
          localStorage.removeItem('p');
        }
      }
      dispatch(updateApiStatus(ApiStatus.succeeded));
    },
    (e: any) => {
      handleError(e);
    },
  );
};

export const registerCall = (data: any, cb: () => void) => {
  toast.dismiss();
  const { dispatch } = store;

  dispatch(updateApiStatus(ApiStatus.loading));
  delete data['termsAndConditions'];

  data['deviceToken'] = `${
    localStorage.getItem('tokenFCM') || generateRandomString(5)
  }`;
  postApiCall(
    endPoints.register,
    data,
    (s: any) => {
      const {
        data: { statusCode },
      } = s;
      if (data && statusCode && statusCode === apiSuccessCode.created) {
        localStorage.setItem('email', data['email']);
        cb();
      }
      dispatch(updateApiStatus(ApiStatus.succeeded));
    },
    (e: any) => {
      handleError(e);
    },
  );
};

export const stepRegisterCall = <T, C>(data: T, cb: (data: C) => void) => {
  const { dispatch } = store;
  dispatch(updateApiStatus(ApiStatus.loading));
  putApiCall(
    endPoints.businessRegister,
    data,
    (s: any) => {
      const {
        data: { statusCode, data },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.accepted) {
        cb(data);
        dispatch(updateApiStatus(ApiStatus.succeeded));
      } else {
        dispatch(updateApiStatus(ApiStatus.failed));
      }
    },
    (e: any) => {
      handleError(e);
    },
  );
};

export const resendOtp = (query: string, cb: (data: any) => void) => {
  const { dispatch } = store;
  dispatch(updateApiStatus(ApiStatus.loading));
  getApiCall(
    endPoints.sendotp + query,
    (s: any) => {
      const {
        data: { statusCode, message },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.success) {
        notify(message, 'success');
        dispatch(updateApiStatus(ApiStatus.succeeded));
        cb(message);
      }
    },
    (e: any) => {
      handleError(e);
      cb(e);
    },
  );
};

export const verifyOtp = <T>(payload: T, cb: (data: any) => void) => {
  const { dispatch } = store;
  dispatch(updateApiStatus(ApiStatus.loading));
  postApiCall(
    endPoints.verify,
    payload,
    (s: any) => {
      const {
        data: { statusCode, data },
      } = s;
      if (data && statusCode && statusCode === apiSuccessCode.success && data) {
        cb(data);
      }
      localStorage.removeItem('email');
      dispatch(updateApiStatus(ApiStatus.succeeded));
    },
    (e: any) => {
      cb(e);
      handleError(e);
    },
  );
};

export const resetPassword = <T>(payload: T, cb: () => void) => {
  const { dispatch } = store;
  dispatch(updateApiStatus(ApiStatus.loading));

  postApiCall(
    endPoints.resetpassword,
    payload,
    (s: any) => {
      const {
        data: { statusCode },
      } = s;
      if (statusCode && statusCode === apiSuccessCode.success) {
        cb();
        dispatch(updateApiStatus(ApiStatus.succeeded));
      }
      dispatch(updateApiStatus(ApiStatus.failed));
    },
    (e: any) => {
      handleError(e);
    },
  );
};

export const updateRejectBanner = (status: any) => {
  const { dispatch } = store;

  dispatch(updateBannerStatus(status));
};

export const updateRejectReason = (status: any) => {
  const { dispatch } = store;

  dispatch(updateBannerReason(status));
};

export const forgotPass = (email: string, cb: (d: any) => void) => {
  const { dispatch } = store;
  dispatch(updateApiStatus(ApiStatus.loading));
  getApiCall(
    `${endPoints.sendotp}?email=${encodeURIComponent(
      email,
    )}&type=FORGOT_PASSWORD`,
    (resp: any) => {
      cb(resp);
      setTimeout(() => {
        dispatch(updateApiStatus(ApiStatus.succeeded));
      }, 1000);
    },
    (e: any) => {
      setTimeout(() => {
        dispatch(updateApiStatus(ApiStatus.failed));
      }, 1000);
      if (e?.data && e?.data.message) {
        notify(e?.data.message, 'error');
      }
    },
  );
};

export const updateProfileDetails = (payload: any, cb: (d: any) => void) => {
  const { dispatch } = store;
  dispatch(updateApiStatus(ApiStatus.loading));
  putApiCall(
    endPoints.profile.getOrUpdate,
    payload,
    (e: any) => {
      const {
        data: { statusCode, data },
      } = e;
      if (statusCode && statusCode === apiSuccessCode.accepted) {
        cb(data);
      }
      dispatch(updateApiStatus(ApiStatus.succeeded));
    },
    (e: any) => {
      handleError(e);
    },
  );
};

export const updatePassword = (payload: any, cb: (resp: any) => void) => {
  putApiCall(
    endPoints.profile.updatePass,
    payload,
    (resp: any) => {
      cb(resp);
    },
    (e: any) => {
      cb(e);
    },
  );
};

export const checkPermissionToAdd = (module: string) => {
  const { getState } = store;
  const {
    auth: {
      userData: { role, permissions },
    },
  }: RootState = getState();
  if (role === USER_ROLES.CO_VENDOR && permissions && permissions[module]) {
    return permissions[module] === PERMISSION_TYPES.EDIT;
  }
  return true;
};

export const updateDeviceTokenForSafari = (token: string) => {
  getApiCall(
    `${endPoints.notification.updateDeviceToken}?token=${token}`,
    (s: any) => {
      console.log(s);
    },
    (e: any) => {
      handleError(e);
    },
  );
};
export const logout = () => {
  const { dispatch } = store;
  dispatch(logoutState());
};
export const logoutCall = () => {
  const { dispatch, getState } = store;
  const {
    common: { loading },
  }: RootState = getState();

  if (!loading) {
    dispatch(setCommonDataLoader(true));
    postApiCall(
      endPoints.logout,
      {},
      () => {
        dispatch(setCommonDataLoader(false));
        dispatch(logoutState());
        dispatch(resetList());
        persistor.purge();
      },
      (e: any) => {
        dispatch(logoutState());
        dispatch(setCommonDataLoader(false));
        // handleError(e);
        persistor.purge();
      },
      true,
    );
  }

  //
};
