import store from '../redux/store';

import axios, { AxiosInstance } from 'axios';
import { logout } from '../redux/auth.slice';

const BASIC_AUTH = `Basic ${btoa('reel:reel@123')}`;

// Extend dayjs with utc plugin
// baseURL: "https://reelqaapi.appskeeper.in/",

const axiosInstance: AxiosInstance = axios.create({
  baseURL: `${process.env.API_URL}`,
  // baseURL: 'https://reelqaapi.appskeeper.in/',
  timeout: 30000,
  headers: {
    api_key: '1234',
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: BASIC_AUTH,
    platform: 3,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    language: 'en',
    offset: new Date().getTimezoneOffset() * 60 * 1000,
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const authToken = store.getState().auth.token;
    if (authToken) {
      config.headers.Authorization = config.url?.includes('country-list')
        ? BASIC_AUTH
        : `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const UNAUTHORIZED = 401;

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    const { url } = error.config;

    if (status === UNAUTHORIZED) {
      !url.includes('logout') && logout();
    }
    return Promise.reject(error);
  },
);

export const setAuthorizationToken = (token?: string) => {
  if (token) {
    axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
  }
};
export const resetAuthorizationToken = () => {
  axiosInstance.defaults.headers['Authorization'] = BASIC_AUTH;
};

export default {
  axiosInstance,
  setAuthorizationToken,
};
