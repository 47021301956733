import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AutocompleteSelect from '../../../Components/AutocompleteSelect';
import CustomButton from '../../../Components/CustomButton';
import DateInput from '../../../Components/DateInput';
import { FileInputWithDND } from '../../../Components/FileInput';
import InputField from '../../../Components/Input';
import Success from '../../../Components/Success';
import { commonUploadFn } from '../../../Utils/AwsService';
import { AIT } from '../../../Utils/Localization';
import { DOC_TYPE, FORM_STEPS } from '../../../Utils/constantData';
import Images from '../../../Utils/images';
import { notify } from '../../../Utils/toastify';
import { LegalDocSchema, fileSchema } from '../../../Utils/yupvalidations';
import { ErrorMsg, MaxFileSizeLimit, ROUTES } from '../../../helpers/contants';
import { saveUserData, stepRegisterCall } from '../../../redux/auth.slice';
import { RootState, useAppSelector } from '../../../redux/store';
import { ApiStatus } from '../../../types';
import { useTranslation } from 'react-i18next';
import ToolTipComponent from '../../../Components/ToolTipComponent';
interface Props {
  changeStep: (step: number) => void;
  setCanMove: (f: boolean) => void;
  setBlockToNavigate: (f: boolean) => void;
}

export default function LegalDocuments({
  setBlockToNavigate,
  setCanMove,
}: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { countryList } = useAppSelector((state: RootState) => state.common);
  const { apiStatus } = useAppSelector((state: RootState) => state.auth);
  const [load, setLoad] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>(null);
  const [progress, setProgress] = useState(0);

  const today = new Date();
  const minDate = new Date(today.setDate(today.getDate() + 1));
  const [s3fileUrl, sets3FileUrl] = useState<string>('');

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(LegalDocSchema),
    mode: 'onTouched',
  });

  useEffect(() => {
    if (isDirty) {
      setCanMove(false);
    }
  }, [isDirty]);

  const onSubmit = (data: any) => {
    const payload = data;
    payload['type'] = FORM_STEPS.LEGAL_DOC;
    payload['documentUrl'] = s3fileUrl;
    payload['countryOfIssue'] = payload['countryOfIssue'].name;
    delete payload['document'];
    stepRegisterCall(payload, (data) => {
      setUserData(data);
      setSuccess(true);
      setBlockToNavigate(true);
    });
  };

  const handleAlphaNumericInput = (e: any) => {
    // Allow alphanumeric characters and some control keys (e.g., Backspace, Arrow keys)
    const allowedKeys = /[a-zA-Z0-9]|Backspace|ArrowLeft|ArrowRight|Delete|Tab/;
    if (!allowedKeys.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleDropDoc = async (files: any) => {
    if (files?.length && files?.length > 0) {
      if (s3fileUrl) {
        sets3FileUrl('');
      }
      if (files[0].size <= MaxFileSizeLimit) {
        if (setValue) {
          setValue('document', files[0]);
        }
        if (fileSchema) {
          const file = files[0];
          try {
            await fileSchema.validateAt('document', { ['document']: file });
          } catch (error: any) {
            error && error.message && notify(error.message, 'error');
            setValue('document', '');
            return;
          }
        }
        commonUploadFn(files[0], setProgress, (url) => {
          if (url) {
            sets3FileUrl && sets3FileUrl(url);
          } else {
            setValue('document', '');
          }
          setProgress(0);
        });
      } else {
        notify(ErrorMsg(3).fileSizeError, 'error');
        return;
      }
    }
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'vendor_registration.legal_document',
  });

  return success ? (
    <Success
      heading={t('thanks_for_registering')}
      title={t('your_registration_has_been_successfully_completed')}
      buttonText={t('go_to_dashboard')}
      handleClick={() => {
        dispatch(
          saveUserData({
            value: userData,
          }),
        );
        navigate(ROUTES.DASHBOARD);
      }}
    />
  ) : (
    <div className="businessInfo_step contactInfo">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <AutocompleteSelect
                requiredField
                id="document_type"
                labelText="Document Type"
                placeHolderText={t('select_document_type')}
                name="documentType"
                control={control}
                error={!!errors['documentType']}
                setValue={setValue}
                helperText={
                  !!errors['documentType']
                    ? `${errors['documentType'].message}`
                    : ''
                }
                options={DOC_TYPE}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <InputField
                requiredField
                id="document_number"
                label={t('document_number')}
                name="documentNumber"
                placeholder={t('enter_document_number')}
                error={!!errors['documentNumber']}
                helperText={
                  !!errors['documentNumber']
                    ? errors['documentNumber'].message
                    : ``
                }
                control={control}
                onKeyPress={handleAlphaNumericInput}
                charLimit={50}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FileInputWithDND
                requiredField
                id="attachment"
                label={t('attachment')}
                name="document"
                placeholder={t('attach_document')}
                control={control}
                showCard={true}
                sizeLimit={MaxFileSizeLimit}
                sizeLimitError={ErrorMsg(10).fileSizeError}
                error={!!errors['document'] && s3fileUrl === ''}
                helperText={
                  !!errors['document'] && s3fileUrl === ''
                    ? `${errors['document'].message}`
                    : ''
                }
                setValue={setValue}
                s3Upload={true}
                s3fileUrl={s3fileUrl}
                setS3FileUrl={sets3FileUrl}
                progress={progress}
                setProgress={setProgress}
                schema={fileSchema}
                startAdornment={
                  <span className="file_pre_text">Choose File</span>
                }
                endAdornment={
                  s3fileUrl ? (
                    // <Tooltip title={t('download')} placement='top'>
                    <ToolTipComponent title={t('download')} theme="dark">
                      <IconButton
                        onClick={() => {
                          window?.open(s3fileUrl, '_blank')?.focus();
                        }}
                      >
                        {/* <FileDownloadIcon fontSize="small" /> */}
                        <img src={Images.DOWNLOAD} alt={AIT().download} />
                      </IconButton>
                    </ToolTipComponent>
                  ) : (
                    // {/* </Tooltip> */}
                    <></>
                  )
                }
                onDrop={handleDropDoc}
              />
            </Grid>
          </Grid>
          <div className="formLabel">
            <h3>{t('country_of_issue')}</h3>
          </div>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <AutocompleteSelect
                requiredField
                id="Country"
                labelText={t('country')}
                placeHolderText={t('select_country')}
                name="countryOfIssue"
                control={control}
                error={!!errors['countryOfIssue']}
                setValue={setValue}
                helperText={
                  !!errors['countryOfIssue']
                    ? `${errors['countryOfIssue'].message}`
                    : ''
                }
                isCountryField={true}
                options={countryList}
                defaultValue=""
                onChange={(e: any, newVal: any) => {
                  clearErrors('countryOfIssue');
                  setValue('countryOfIssue', newVal);
                }}
                labelKey="name"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <DateInput
                minDate={`${minDate}`}
                requiredField
                disabled={false}
                label={t('expiry_date')}
                name="dateOfExp"
                formProps={{ control }}
                error={!!errors['dateOfExp']}
                helperText={
                  !!errors['dateOfExp'] && (
                    <p className="helper-error">
                      {errors['dateOfExp'].message}
                    </p>
                  )
                }
              />
            </Grid>
          </Grid>
        </Box>
        <Box className="buttonContainer">
          <CustomButton
            size="large"
            variant="contained"
            text={t('submit')}
            showIcon={false}
            width="100%"
            type="submit"
            id="login"
            disabled={!isValid}
            loading={load || apiStatus === ApiStatus.loading}
          />
        </Box>
      </form>
    </div>
  );
}
